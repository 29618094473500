import "./carrinho.css";
import React, { useEffect, useState } from "react";
import { BuscaLocal } from "../../../conexao/localstorageBusca";
import { Dropdown } from "primereact/dropdown";
import "primereact/dropdown";
import { classNames } from "primereact/utils";
import { BsFillCartFill, BsBasket2Fill } from "react-icons/bs";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { ProdutoCarrinho } from "./produtos/produtosCarrinho";
import {
  CorFonte,
  CorFundo,
  DeliveryAtivo,
  DeliveryMensagem,
  StatusLoja,
  UserId,
  ValidarBairro,
  ValidaStatusLoja,
  VemBuscarAtivo,
  VemBuscarMensagem,
} from "../../util/validaAbertura";
import { Notificacao } from "../../util/notificacao";
import { API } from "../../../conexao/api";
import { ApagaLocal, GravaLocal } from "../../../conexao/localstorage";
import { PrimeiraLetraMaiuscula } from "../../util/primeiraLetraMaiuscula";
import { formatoBrasileiro } from "../../util/formatacaoMoeda";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { ValidaEstoqueProduto } from "../../util/validaestoqueproduto";
import { LimpaCache } from "../../finalizacao/limparcache";
import ReactPixel from "react-facebook-pixel";
import { PIXELFACEBOOK } from "../../util/datahora";
import { useParams, Link } from "react-router-dom";

var dadosUsuario = JSON.parse(BuscaLocal("dados_cliente"));
var CupomDescontoVDesconto = [];
var Bairros = [];
var TipoPagamento = [];
var DadosTipoPagamento = [];
var CupomDescontoVDescontoOff = false;
var CarregouDados = false;
var TemPìx = JSON.parse(BuscaLocal("dados_empresa"));
var TaxaPix = 0;

let PontosDescontoFidelidade = 0;
let ValorDescontoFidelidade = 0;
let PedidoMinFidelidade = 0;

// TemPìx = false;

function apenasNumeros(str) {
  return str.replace(/[^0-9]/g, "");
}

function validarFormaPagamento(formaPagamento, value) {
  // Retira acentos da string e converte para minúsculas
  const formaPagamentoSemAcento = formaPagamento
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
  console.log(formaPagamentoSemAcento);
  // Verifica se a string é igual a "cartao", "dinheiro" ou "pix"
  if (formaPagamentoSemAcento.includes("pix")) {
    TipoPagamento.push({
      label: PrimeiraLetraMaiuscula(formaPagamento),
      value: value,
      icon: "fa-brands fa-pix",
      cor: "rgb(0,189,174)",
    });
  } else if (formaPagamentoSemAcento.includes("credito")) {
    TipoPagamento.push({
      label: PrimeiraLetraMaiuscula(formaPagamento),
      value: value,
      icon: "fa-brands fa-cc-visa",
      cor: "rgb(0,0,0)",
    });
  } else if (formaPagamentoSemAcento.includes("debito")) {
    TipoPagamento.push({
      label: PrimeiraLetraMaiuscula(formaPagamento),
      value: value,
      icon: "fa-brands fa-cc-mastercard",
      cor: "rgb(0,0,0)",
    });
  } else if (formaPagamentoSemAcento.includes("qrcod")) {
    TipoPagamento.push({
      label: PrimeiraLetraMaiuscula(formaPagamento),
      value: value,
      icon: "fa-solid fa-qrcode",
      cor: "rgb(0,0,0)",
    });
  } else {
    TipoPagamento.push({
      label: PrimeiraLetraMaiuscula(formaPagamento),
      value: value,
      icon: "pi pi-money-bill",
      cor: "rgb(3, 102, 0)",
    });
  }
}

var Cidade = "";
var Estado = "";

export function CarrinhoPedido(props) {
  const { partner } = useParams();
  const { cupom } = useParams();
  // const { CupomDescontoV } = useParams();

  try {
    var telefoneLocal = dadosUsuario[0]["telefone"];
    var ruaLocal = PrimeiraLetraMaiuscula(dadosUsuario[0]["rua"]);
    var numeroLocal = dadosUsuario[0]["unidade"];
    var complementoLocal = PrimeiraLetraMaiuscula(
      dadosUsuario[0]["complemento"]
    );
    var bairroLocal = dadosUsuario[0]["bairro"];
    var nomeLocal = PrimeiraLetraMaiuscula(dadosUsuario[0]["nome"]);
    var cpf = apenasNumeros(PrimeiraLetraMaiuscula(dadosUsuario[0]["cpf"]));
    var nascimento = apenasNumeros(
      PrimeiraLetraMaiuscula(dadosUsuario[0]["nascimento"])
    );
  } catch (error) {
    var telefoneLocal = "";
    var ruaLocal = "";
    var numeroLocal = "";
    var complementoLocal = "";
    var bairroLocal = "";
    var nomeLocal = "";
    var cpf = "";
    var nascimento = "";
  }

  const navigate = useNavigate();
  const [Mesa_Descricao, setMesa_Descricao] = useState(
    BuscaLocal("MESA_DESCRICAO")
  );
  const [Mesa_Numero, setMesa_Numero] = useState(BuscaLocal("MESA_NUMERO"));
  const [deliveryOption, setDeliveryOption] = useState("");
  const [atualiza, setAtualiza] = useState(false);
  const [telefone, setPhone] = useState(telefoneLocal);
  const [rua, setRua] = useState(ruaLocal);
  const [numero, setNumero] = useState(numeroLocal);
  const [complemento, setComplemento] = useState(complementoLocal);
  const [bairro, setBairro] = useState(bairroLocal);
  const [DadosUsuarios, setDadosUsuarios] = useState(dadosUsuario);
  const [CupomDescontoV, setCupomDescontoV] = useState(BuscaLocal("CUPOM"));
  const [validaCupomDescontoV, setValidaCupomDescontoV] = useState(false);
  const [TemCupomDescontoV, setTemCupomDescontoV] = useState(false);
  const [Pagamento, setPagamento] = useState(null);
  const [nome, setNome] = useState(nomeLocal);
  const [CPF, setCPF] = useState(cpf);
  const [DataNascimento, setDataNascimento] = useState(nascimento);
  const [ProdutoForaEstoque, setProdutoForaEstoque] = useState(false);
  const [ExclusivoDelivery, setExclusivoDelivery] = useState(false);
  const [ExclusivoVembuscar, setExclusivoVembuscar] = useState(false);
  const [DadosCarrinho, setDadosCarrinho] = useState(
    JSON.parse(BuscaLocal("produtos_selecionados"))
  );
  const [troco, settroco] = useState("");
  const [VaiTroco, setVaiTroco] = useState(false);
  const [FinalizandoPedido, setFinalizandoPedido] = useState(true);
  const [PIX, setPix] = useState(false);
  const [PedidoMin, setPedidoMin] = useState(0);
  const [ExibirTaxa, setExibirTaxa] = useState(TaxaPix);
  const [UsaPontosDesconto, setUsaPontosDesconto] = useState(false);

  // Fidelidade
  const [TemFidelidade, setTemFidelidade] = useState(false);
  const [MeusPontos, setMeusPontos] = useState(0);
  const [PontosUtilizados, setPontosUtilizados] = useState(0);
  const [DescontoFidelidade, setDescontoFidelid] = useState(0);
  const [ModalTroco, setModalTroco] = useState(false);
  const [ModalTrocoDialog, setModalTrocoDialog] = useState(false);

  function AddPontoUsado(ponto, valor) {
    setPontosUtilizados(parseInt(PontosUtilizados) + parseInt(ponto));
    setDescontoFidelid(parseFloat(DescontoFidelidade) + parseFloat(valor));
  }

  function RemovePontoUsado(ponto, valor) {
    setPontosUtilizados(parseInt(PontosUtilizados) - parseInt(ponto));
    setDescontoFidelid(parseFloat(DescontoFidelidade) - parseFloat(valor));
  }

  function PontosDisponivel() {
    return parseInt(MeusPontos) - parseInt(PontosUtilizados);
  }

  useEffect(() => {
    if (CupomDescontoV != "") {
    } else {
      if (cupom != "") {
        setCupomDescontoV(cupom);
      }
    }

    var Empresa = JSON.parse(BuscaLocal("dados_empresa"));
    PontosDescontoFidelidade = parseInt(Empresa[0].fidelidade_pontos);
    ValorDescontoFidelidade = parseInt(Empresa[0].fidelidade_desc);
    PedidoMinFidelidade = parseFloat(Empresa[0].fidelidade_min);
    setTemFidelidade(parseInt(Empresa[0].fidelidade_status) == 1);

    if (TemFidelidade) {
      API.get(
        `pontosfidelidade.php?user=${UserId()}&cpf=${apenasNumeros(CPF)}`
      ).then(function (response) {
        setMeusPontos(response.data[0].saldo ? response.data[0].saldo : 0);
      });
    }
    TemPìx = JSON.parse(BuscaLocal("dados_empresa"));
    try {
      TaxaPix =
        parseFloat(TemPìx[0].tax_goopedir) +
        parseFloat(TemPìx[0].tax_mercado_pago);
      TemPìx = TemPìx[0].access_token_mp != "";
    } catch (error) {
      TemPìx = false;
    }
    if (BuscaLocal("CUPOM") != null) {
      ValidarCupomDescontoVDesconto();
    }
  }, []);

  useEffect(() => {
    API.get(`consultacpf.php?user=${UserId()}&celular=${telefoneLocal}`).then(
      function (response) {
        // setMeusPontos(response.data[0].saldo ? response.data[0].saldo : 0);

        if (response.data[0].cpf != null) {
          setCPF(response.data[0]?.cpf);
          setDataNascimento(response.data[0]?.nascimento);
        }
      }
    );
  }, [telefoneLocal]);

  useEffect(() => {
    if (CPF != "") {
      if (TemFidelidade) {
        API.get(
          `pontosfidelidade.php?user=${UserId()}&cpf=${apenasNumeros(CPF)}`
        ).then(function (response) {
          setMeusPontos(response.data[0].saldo ? response.data[0].saldo : 0);
        });
      }
    }
  }, [CPF]);

  useEffect(() => {
    if (TemFidelidade) {
      API.get(
        `pontosfidelidade.php?user=${UserId()}&cpf=${apenasNumeros(CPF)}`
      ).then(function (response) {
        setMeusPontos(response.data[0].saldo ? response.data[0].saldo : 0);
      });
    }
  }, [TemFidelidade]);

  function DescricaoTipoPagamento() {
    for (let i = 0; i < TipoPagamento.length; i++) {
      if (TipoPagamento[i].value == Pagamento) {
        setVaiTroco(TipoPagamento[i].label.toUpperCase().indexOf("DIN") > -1);
        if (TemPìx) {
          setPix(TipoPagamento[i].label.toUpperCase().indexOf("PIX") > -1);
        } else {
          setPix(false);
        }
      }
    }
  }

  if (!CarregouDados) {
    if (UserId() > 0) {
      setMesa_Descricao(BuscaLocal("MESA_DESCRICAO"));
      setMesa_Numero(BuscaLocal("MESA_NUMERO"));

      CarregouDados = true;

      API.get("bairro.php?codigo=" + UserId()).then(function (response) {
        for (var i = 0; i < response.data.length; i++) {
          Bairros.push({
            label:
              PrimeiraLetraMaiuscula(response.data[i]["bairro"]) +
              " / " +
              PrimeiraLetraMaiuscula(response.data[i]["cidade"]) +
              " - " +
              formatoBrasileiro.format(response.data[i]["taxa"]),
            value: response.data[i]["bairro"],
            taxa: response.data[i]["taxa"],
            icon: "pi pi-map-marker",
            cidade: response.data[i]["cidade"],
            estado: response.data[i]["estado"],
          });
        }
      });

      API.get(
        "generica.php?tabela=ws_formas_pagamento&where=user_id = " + UserId()
      ).then(function (response) {
        DadosTipoPagamento = response.data;

        for (var i = 0; i < response.data.length; i++) {
          validarFormaPagamento(
            response.data[i]["f_pagamento"],
            response.data[i]["id_f_pagamento"]
          );
        }
      });

      if (Mesa_Numero == 0) {
        API.get(
          "generica.php?tabela=cupom_desconto&where=user_id = " +
            UserId() +
            " and total_vezes > 0 and data_validade > current_date"
        ).then(
          // API.get("generica.php?tabela=CupomDescontoV_desconto&where=user_id = 1 and total_vezes > 0 and data_validade > current_date").then(
          function (response) {
            CupomDescontoVDesconto = response.data;

            if (CupomDescontoVDesconto == null) {
              CupomDescontoVDescontoOff = false;
            } else {
              CupomDescontoVDescontoOff = true;
              // setValidaCupomDescontoV(true);
              setTemCupomDescontoV(true);
            }
          }
        );
      } else {
        setPagamento(TipoPagamento[0]["value"]);
        setTemCupomDescontoV(false);
        setNome(Mesa_Descricao);
        setPhone(Mesa_Numero);
      }
    }
  }

  function AcessarResumo(pedido) {
    navigate("/" + BuscaLocal("NOME_LINK_EMPRESA") + "/" + pedido);
    window.location.reload();
  }

  var deliveryOptions = [];

  const handlePhoneChange = (event) => {
    var celular = apenasNumeros(event.target.value);

    if (celular.length == 11) {
      setPhone(celular);

      API.get(
        "generica.php?tabela=ws_pedidos&where=telefone = '" +
          celular +
          "' and user_id = '" +
          UserId() +
          "' and opcao_delivery <> 'false' order by ws_pedidos.id desc limit 1"
      ).then(function (response) {
        GravaLocal("dados_cliente", JSON.stringify(response.data));
        setNome(PrimeiraLetraMaiuscula(response.data[0]["nome"]));
        setRua(PrimeiraLetraMaiuscula(response.data[0]["rua"]));
        setBairro(response.data[0]["bairro"]);
        setNumero(response.data[0]["unidade"]);
        setComplemento(PrimeiraLetraMaiuscula(response.data[0]["complemento"]));
        setCPF(apenasNumeros(PrimeiraLetraMaiuscula(response.data[0]["cpf"])));
        setDataNascimento(
          apenasNumeros(PrimeiraLetraMaiuscula(response.data[0]["nascimento"]))
        );
      });
    }
  };

  //deliveryOptions
  if (VemBuscarAtivo() == "true") {
    deliveryOptions.push({
      label: "Vem Buscar",
      value: "Vem Buscar",
      icon: "pi pi-home",
      descricao: "Você retira no nosso endereço",
      tempo: " - " + VemBuscarMensagem(),
    });
  }

  if (DeliveryAtivo() == "true") {
    deliveryOptions.push({
      label: "Delivery",
      value: "Delivery",
      icon: "pi pi-map-marker",
      descricao: "A gente leva até você",
      tempo: " - " + DeliveryMensagem(),
    });
  }

  const itemTemplatePagamento = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        {/* <i className={classNames('pi', option.icon)} style={{ marginRight: '20px' }}></i> */}
        <i
          className={option.icon}
          style={{ marginRight: "10px", color: option.cor }}
        ></i>
        <span className="p-ml-2">{option.label}</span>
        <br></br>
      </div>
    );
  };

  const SelectitemTemplatePagamento = (option, props) => {
    if (option) {
      return (
        <div className="p-d-flex p-ai-center">
          {/* <i className={classNames('pi', option.icon)} style={{ marginRight: '20px' }}></i> */}
          <i
            className={option.icon}
            style={{ marginRight: "10px", color: option.cor }}
          ></i>
          <span className="p-ml-2">{option.label}</span>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const itemTemplate = (option) => {
    return (
      <div className="p-d-flex p-ai-center">
        <i
          className={classNames("pi", option.icon)}
          style={{ marginRight: "10px" }}
        ></i>
        <span className="p-ml-2">{option.label}</span>
        <br></br>

        <span className="p-ml-2" style={{ color: "#464646" }}>
          {option.descricao}
        </span>
        <br></br>
        <span className="p-ml-2" style={{ color: "#bb1111d2" }}>
          {option.tempo}
        </span>
      </div>
    );
  };

  const SelectitemTemplate = (option, props) => {
    if (option) {
      return (
        <div className="p-d-flex p-ai-center">
          <i
            className={classNames("pi", option.icon)}
            style={{ marginRight: "10px" }}
          ></i>
          <span className="p-ml-2">
            {option.label}
            {option.tempo}
          </span>
          {/* <span className="p-ml-2" style={{ color: '#ccc' }}>{option.descricao}</span> */}
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  function SomaTotal(produto) {
    var quantidadeProduto = produto.quantidade_selecionada;
    var valorProduto = produto.valor_produto;
    var valorDelivery = produto.adicional_delivery_produto;
    var ValorSabores = 0;
    var Maior = 0;

    for (var i = 0; i < produto.adicionais.length; i++) {
      valorProduto =
        parseFloat(valorProduto) +
        parseFloat(produto.adicionais[i]["valor_adicional"]) *
          parseFloat(produto.adicionais[i]["quantidade_adicional"]);
    }

    for (var i = 0; i < produto.pizza.length; i++) {
      if (parseFloat(produto.pizza[i]["quantidade"]) > 0) {
        ValorSabores =
          parseFloat(ValorSabores) +
          parseFloat(produto.pizza[i]["valor_pizza"]) *
            parseFloat(produto.pizza[i]["quantidade"]);

        if (parseFloat(produto.pizza[i]["valor_pizza"]) > parseFloat(Maior)) {
          Maior = parseFloat(produto.pizza[i]["valor_pizza"]);
        }
      }
    }
    if (parseFloat(ValorSabores) > 0) {
      if (parseInt(produto.pizza[0]["tipo_valor"]) == 0) {
        ValorSabores =
          parseFloat(ValorSabores) / parseFloat(produto.pizza[0]["max"]);
        //Média
      } else if (parseInt(produto.pizza[0]["tipo_valor"]) == 1) {
        //Maior
        ValorSabores = parseFloat(Maior);
      } else {
        //Soma
      }

      valorProduto = parseFloat(valorProduto) + parseFloat(ValorSabores);
    }

    if (deliveryOption == "Delivery") {
      valorProduto = parseFloat(valorProduto) + parseFloat(valorDelivery);
    }

    return parseFloat(valorProduto);
  }

  function Descricao(produto) {
    var descricao = produto.observacao_adicionada;

    for (var i = 0; i < produto.pizza.length; i++) {
      if (parseFloat(produto.pizza[i]["quantidade"]) > 0) {
        if (descricao == "") {
          descricao =
            "(" +
            produto.pizza[i]["quantidade"] +
            "/" +
            produto.pizza[i]["max"] +
            ") " +
            produto.pizza[i]["sabor_pizza"];
        } else {
          descricao =
            descricao +
            ", (" +
            produto.pizza[i]["quantidade"] +
            "/" +
            produto.pizza[i]["max"] +
            ") " +
            produto.pizza[i]["sabor_pizza"];
        }
      }
    }

    for (var i = 0; i < produto.adicionais.length; i++) {
      if (descricao == "") {
        descricao = produto.adicionais[i]["nome_adicional"];
      } else {
        descricao = descricao + ", " + produto.adicionais[i]["nome_adicional"];
      }
    }

    return descricao;
  }

  function DescricaoPagamento() {
    //Pagamento
    // TipoPagamento.push({ label: PrimeiraLetraMaiuscula(formaPagamento), value: value, icon: 'pi pi-money-bill', cor: 'rgb(3, 102, 0)' })
    for (var i = 0; i < TipoPagamento.length; i++) {
      if (TipoPagamento[i].value == Pagamento) {
        return TipoPagamento[i].label;
      }
    }
  }

  const AlgumProdutoForaDeEstoque = () => {
    setProdutoForaEstoque(true);
  };

  const ProdutoExclusivoVembuscar = (nome) => {
    setExclusivoVembuscar(true);
  };

  const ProdutoExclusivoDelivery = (nome) => {
    setExclusivoDelivery(true);
  };

  function ValorPedido() {
    var ValorPedido = 0;

    for (var i = 0; i < DadosCarrinho.length; i++) {
      ValorPedido =
        ValorPedido +
        SomaTotal(DadosCarrinho[i]) *
          DadosCarrinho[i]["quantidade_selecionada"];
    }
    return ValorPedido;
  }

  function ValorPedidoFidelidade() {
    return Math.round(parseFloat(TotalPedidoComPix()));
  }

  function ValorDelivery() {
    var Taxa = 0;

    if (deliveryOption == "Delivery") {
      for (var i = 0; i < Bairros.length; i++) {
        if (Bairros[i]["value"] == bairro) {
          Cidade = Bairros[i]["cidade"];
          Estado = Bairros[i]["estado"];

          Taxa = parseFloat(Bairros[i]["taxa"]);
          break;
        }
      }
    }

    return parseFloat(Taxa);
  }
  function ValorDesconto() {
    var Descont = 0;
    var dadosCupomDescontoV = [];
    try {
      dadosCupomDescontoV = JSON.parse(BuscaLocal("CupomDescontoV_DESCONTO"));
    } catch (error) {}

    if (dadosCupomDescontoV == null) {
      dadosCupomDescontoV = [];
    }

    if (dadosCupomDescontoV.length > 0) {
      if (dadosCupomDescontoV[0]["type_discount"] == 1) {
        Descont =
          (parseFloat(ValorPedido()) *
            parseFloat(dadosCupomDescontoV[0]["porcentagem"])) /
          100;
      } else {
        Descont = parseFloat(dadosCupomDescontoV[0]["fixed_value"]);
      }
    }

    return Descont;
  }

  function TotalPedido() {
    var tot =
      parseFloat(ValorPedido()) +
      parseFloat(ValorDelivery()) -
      parseFloat(ValorDesconto()) -
      parseFloat(DescontoFidelidade);

    return tot;
  }

  function TotalPedidoComPix() {
    var tot = 0;

    if (PIX) {
      tot = TotalPedido() + (TotalPedido() * TaxaPix) / 100;
    } else {
      tot = TotalPedido();
    }

    return tot;
  }

  function TotalTax() {
    //{PIX ? <><h3 style={{ marginTop: '10px', color: 'rgba(0,0,0,0.7)' }}>*Taxa Serviço PIX <b>GooPedir</b> - {formatoBrasileiro.format((TotalPedido() * 3) / 100)}</h3></> : null}
    var tot = (TotalPedido() * TaxaPix) / 100;
    return tot;
  }

  function validarCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, ""); // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
      return false; // CPF deve ter 11 dígitos e não pode ter todos os dígitos iguais
    }

    // Calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

    // Calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

    // Verifica se os dígitos verificadores calculados são iguais aos fornecidos no CPF
    return (
      parseInt(cpf.charAt(9)) === digitoVerificador1 &&
      parseInt(cpf.charAt(10)) === digitoVerificador2
    );
  }

  const FinalizaTroco = () => {
    // Allan;

    if (troco == null) {
      setModalTrocoDialog(true);
      return false;
    }
    if (TotalPedido() > troco) {
      Notificacao(
        "erro",
        "Valor do troco não pode ser inferior ao total do pedido!"
      );
      return false;
    }
    FinalizarPedidoPost();
  };

  const FinalizarPedidoPost = () => {
    setModalTroco(false);
    var FinalizouPedido = true;
    var numeroCelular = telefone;
    var Nome = nome;
    var TipoPagamentopedido = Pagamento;
    var DadosPedido = [];
    var LinkPedido = [];

    var tipo_pedido = false;
    var endereco_rua = "";
    var endereco_bairro = "";
    var endereco_cidade = "";
    var endereco_estado = "";
    var endereco_numero = "";
    var endereco_complemento = "";

    if (deliveryOption == "Delivery") {
      tipo_pedido = true;
      endereco_rua = rua;
      endereco_bairro = bairro;
      endereco_cidade = Cidade;
      endereco_estado = Estado;
      endereco_numero = numero;
      endereco_complemento = complemento;
    }

    DadosPedido.push({
      user_id: UserId(),
      telefone: numeroCelular,
      nome: Nome,
      tipo: tipo_pedido,
      tipo_pagamento: DescricaoPagamento(),
      pedido: ValorPedido(),
      desconto: ValorDesconto(),
      cupom: CupomDescontoV,
      taxa: ValorDelivery(),
      total: TotalPedido(),
      rua: endereco_rua,
      troco: troco == "" ? 0 : troco,
      numero: endereco_numero,
      complemento: endereco_complemento,
      bairro: endereco_bairro,
      cidade: endereco_cidade,
      estado: endereco_estado,
      produtos: DadosCarrinho,
      url: window.location.href + "/",
      partner: partner ? partner : "site",
      cpf: CPF,
      nascimento: DataNascimento,
      fidelidade: DescontoFidelidade ? DescontoFidelidade : 0,
      pontos: PontosUtilizados ? PontosUtilizados : 0,
      pix: TemPìx && PIX,
    });

    GravaLocal("PEDIDO_FINALIZADO", JSON.stringify(DadosPedido));

    GravaLocal("ULTIMO_PEDIDO", JSON.stringify(DadosCarrinho));

    ReactPixel.init(PIXELFACEBOOK());
    ReactPixel.trackCustom("Purchase", {
      empresa: BuscaLocal("NOME_LINK_EMPRESA"),
      dados: DadosPedido,
      currency: "BRL",
      value: TotalPedido(),
    });

    API.post("novopedido.php", DadosPedido).then(function (response) {
      LinkPedido = response.data;
      API.get(
        "generica.php?tabela=ws_pedidos&where=telefone = '" +
          numeroCelular +
          "' and user_id = '" +
          UserId() +
          "' and opcao_delivery <> 'false' order by ws_pedidos.id desc limit 1"
      ).then(function (response) {
        GravaLocal("dados_cliente", JSON.stringify(response.data));

        setCupomDescontoV("");
        ApagaLocal("produtos_selecionados");
        LimpaCache();
        AcessarResumo(LinkPedido);
      });
    });

    Notificacao("sucesso", "Pedido recebido com sucesso! ❤️");
  };

  const FinalizarPedido = async () => {
    // Validar o bairro

    setFinalizandoPedido(false);
    var FinalizouPedido = true;
    var numeroCelular = telefone;
    var Nome = nome;
    var TipoPagamentopedido = Pagamento;
    if (Mesa_Numero > 0) {
      Nome = Mesa_Descricao;
      TipoPagamentopedido = TipoPagamento[0]["value"];
      numeroCelular = Mesa_Numero;
    } else {
      if (TemFidelidade) {
        if (!validarCPF(CPF)) {
          Notificacao("", "CPF inválido!");
          setFinalizandoPedido(true);

          return false;
        }
        if (DataNascimento == "") {
          Notificacao("", "Data de Nascimento inválida!");
          setFinalizandoPedido(true);

          return false;
        }
      }
    }
    let status = await ValidaStatusLoja();

    if (!status) {
      FinalizouPedido = false;
      Notificacao("", "Loja fechada no momento!");
      setFinalizandoPedido(true);
      return false;
    } else {
      if (DescontoFidelidade > 0) {
        if (ValorPedido() < PedidoMinFidelidade) {
          Notificacao(
            "",
            "❌❌ Para utilizar seus pontos de fidelidade, seu pedido deve ter pelomenos " +
              formatoBrasileiro.format(PedidoMinFidelidade) +
              " 💸💸"
          );
          setFinalizandoPedido(true);
          return false;
        }
      }

      try {
        var dadosCupomDescontoV = JSON.parse(
          BuscaLocal("CupomDescontoV_DESCONTO")
        );
        var CupomDescontoV = "";
        if (dadosCupomDescontoV == null) {
        } else if (dadosCupomDescontoV.length > 0) {
          CupomDescontoV = dadosCupomDescontoV[0]["ativacao"];
          await API.get(
            "generica.php?tabela=cupom_desconto&where=user_id = " +
              UserId() +
              " and total_vezes > 0 and data_validade > current_date and ativacao = '" +
              CupomDescontoV.toUpperCase() +
              "'"
          ).then(function (response) {
            dadosCupomDescontoV = response.data;
            GravaLocal(
              "CupomDescontoV_DESCONTO",
              JSON.stringify(response.data)
            );
          });
        }
      } catch (error) {}

      if (dadosCupomDescontoV) {
        if (parseInt(dadosCupomDescontoV[0].primeira) == 1) {
          await API.get(
            `generica.php?tabela=ws_pedidos&where=user_id = ${UserId()} and telefone = '${numeroCelular}' limit 1`
          ).then(function (response) {
            if (response.data != null) {
              setCupomDescontoV("");
              ApagaLocal("CUPOM");
              ApagaLocal("CupomDescontoV_DESCONTO");
              Notificacao(
                "",
                "Cupom disponivel apenas para a primeira compra, ele foi removido do seu carrinho. Conclua seu pedido novamente!"
              );
              FinalizouPedido = false;
              setFinalizandoPedido(true);
              return false;
            }
          });
        }
      }

      // return "allan";

      // ValorPedido() > PedidoMin
      if (PedidoMin > 0) {
        if (PedidoMin > ValorPedido()) {
          Notificacao(
            "",
            "Total dos produtos deve ser maior que o valor mínimo! 😪😪"
          );
          setFinalizandoPedido(true);
          return false;
        }
      }

      // const [ExclusivoDelivery, setExclusivoDelivery] = useState(false);
      // const [ExclusivoVembuscar, setExclusivoVembuscar] = useState(false);

      if (ExclusivoDelivery) {
        Notificacao(
          "",
          "Você possue produtos exclusivo do DELIVERY, você vai RETIRAR seu pedido! Esse produto deve ser removido do carrinho."
        );
        FinalizouPedido = false;
        setFinalizandoPedido(true);
        setExclusivoDelivery(false);
        setExclusivoVembuscar(false);
      } else if (ExclusivoVembuscar) {
        Notificacao(
          "",
          "Você possue produtos exclusivo do VEM BUSCAR, você vai receber seu pedido em casa! Esse produto deve ser removido do carrinho."
        );
        FinalizouPedido = false;
        setFinalizandoPedido(true);
        setExclusivoDelivery(false);
        setExclusivoVembuscar(false);
      } else if (ProdutoForaEstoque) {
        FinalizouPedido = false;
        Notificacao(
          "",
          "Lamentamos mais algum produto do seu carrinho está fora de estoque! 😪😪"
        );
        Notificacao("", "Remova ele para poder finalizar o pedido! 🤩🤩");
        setFinalizandoPedido(true);
      } else if (!numeroCelular ?? "") {
        FinalizouPedido = false;
        Notificacao("", "Informe o celular! 📲");
        setFinalizandoPedido(true);
      } else if (!Nome ?? "") {
        FinalizouPedido = false;
        Notificacao("", "Informe o nome! ✏️");
        setFinalizandoPedido(true);
      } else if (!TipoPagamentopedido ?? "") {
        FinalizouPedido = false;
        Notificacao("", "Selecione o meio de pagamento! 💸");
        setFinalizandoPedido(true);
      } else if (!deliveryOption ?? "") {
        if (Mesa_Numero > 0) {
        } else {
          FinalizouPedido = false;
          Notificacao("", "Selecione qual forma deseja receber seu pedido! 📍");
          setFinalizandoPedido(true);
        }
      }
      if (deliveryOption == "Delivery") {
        if (!rua ?? "") {
          FinalizouPedido = false;
          Notificacao("", "Informe a rua! 🏠");
          setFinalizandoPedido(true);
        } else if (!numero ?? "") {
          FinalizouPedido = false;
          Notificacao("", "Informe o número! 🏠");
          setFinalizandoPedido(true);
        } else if (!complemento ?? "") {
          FinalizouPedido = false;
          Notificacao("", "Informe um ponto de refêrencia! 🏠");
          setFinalizandoPedido(true);
        } else if (!bairro ?? "") {
          FinalizouPedido = false;
          Notificacao("", "Selecione o bairro! 🏠");
          setFinalizandoPedido(true);
        }

        let Mybairro = await ValidarBairro(bairro);

        if (!Mybairro) {
          Notificacao("", "Selecione o bairro! 🏠");
          setFinalizandoPedido(true);
          return false;
        }

        //Validar o bairro
      }

      if (FinalizouPedido) {
        if (VaiTroco) {
          setModalTroco(true);
        } else {
          FinalizarPedidoPost();
        }
      }
    }
  };

  const Deletar = (index) => {
    if (DadosCarrinho.length == 0) {
      props.onClose();
      Notificacao(0, "Ops.. Você não possui mais produtos no carrinho! 👀👀");
    }

    const novoDadosCarrinho = [...DadosCarrinho];
    novoDadosCarrinho.splice(index, 1);
    GravaLocal("produtos_selecionados", JSON.stringify(novoDadosCarrinho));
    setDadosCarrinho(novoDadosCarrinho);

    // setAtualiza(false);
    // setAtualiza(true);
    // props.onClose();
  };

  const ValidaCupomDescontoV = () => {
    setValidaCupomDescontoV(true);
  };

  const closeCupomDescontoV = () => {
    setValidaCupomDescontoV(false);
  };

  const ValidarCupomDescontoVDesconto = () => {
    try {
      API.get(
        "generica.php?tabela=cupom_desconto&where=user_id = " +
          UserId() +
          " and total_vezes > 0 and data_validade > current_date and ativacao = '" +
          CupomDescontoV.toUpperCase() +
          "'"
      ).then(function (response) {
        GravaLocal("CupomDescontoV_DESCONTO", JSON.stringify(response.data));
        var dadosCupomDescontoV = JSON.parse(
          BuscaLocal("CupomDescontoV_DESCONTO")
        );
        if (dadosCupomDescontoV == null) {
          Notificacao("erro", "Cupom De Desconto inválido 😓😓");
          setCupomDescontoV("");
        } else if (dadosCupomDescontoV.length > 0) {
          // CupomDescontoVLink = dadosCupomDescontoV[0]['ativacao'];
          Notificacao(
            "sucesso",
            "Cupom " +
              dadosCupomDescontoV[0]["ativacao"] +
              " foi aplicado com sucesso 🤩🥳"
          );
          setPedidoMin(dadosCupomDescontoV[0]["valor_min"]);
          closeCupomDescontoV();
        } else {
          Notificacao("erro", "Cupom De Desconto inválido 😓😓");
          setCupomDescontoV("");
          // CupomDescontoVLink = '';
        }
      });
    } catch (error) {}
  };

  const SelecionouBairro = (dados) => {
    setBairro(dados.value);
  };

  useEffect(() => {
    DescricaoTipoPagamento();
  }, [Pagamento]);

  function checkFidelidade(e) {
    if (!UsaPontosDesconto) {
      setDescontoFidelid(DescontoFidelidade + ValorDescontoFidelidade);
      setPontosUtilizados(
        parseInt(PontosUtilizados) + parseInt(PontosDescontoFidelidade)
      );
    } else {
      setDescontoFidelid(DescontoFidelidade - ValorDescontoFidelidade);
      setPontosUtilizados(
        parseInt(PontosUtilizados) - parseInt(PontosDescontoFidelidade)
      );
    }
    setUsaPontosDesconto(!UsaPontosDesconto);
  }

  const CardDescontoFidelidade = () => {
    return (
      <>
        {PontosDescontoFidelidade > 0 ? (
          <>
            {PontosDisponivel() > PontosDescontoFidelidade ? (
              <>
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="myCheckbox"
                      checked={UsaPontosDesconto}
                      onChange={(e) => checkFidelidade(e)}
                    />
                    <label for="myCheckbox"></label>
                  </div>
                  <label
                    htmlFor="myCheckbox"
                    style={{ marginLeft: "5px", marginBottom: "10px" }}
                  >
                    <span style={{ color: "green", marginLeft: "" }}>
                      Utilizar {PontosDescontoFidelidade} pontos por{" "}
                      {formatoBrasileiro.format(ValorDescontoFidelidade)} de
                      desconto?
                    </span>
                  </label>
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </>
    );
  };

  function NaoPrecisaTroco() {
    settroco(TotalPedido());
    setModalTrocoDialog(false);
    FinalizarPedidoPost();
  }

  return (
    <>
      {/* <Dialog visible={true} onHide={closeCupomDescontoV} /> */}
      {validaCupomDescontoV && (
        <Dialog
          header="Cupom"
          visible={validaCupomDescontoV}
          onHide={closeCupomDescontoV}
        >
          <div className="p-fluid">
            <span
              className="p-float-label"
              style={{ width: "100%", marginBottom: "10px" }}
            >
              <InputText
                id="Cupom"
                name="Cupom"
                label="Cupom"
                floatLabel
                style={{ width: "100%" }}
                value={CupomDescontoV}
                onChange={(e) => setCupomDescontoV(e.target.value)}
              />
              {/* <label htmlFor="Cupom" style={{ marginLeft: "-10px" }}>
                Cupom
              </label> */}
            </span>
            <Button
              label="Validar"
              icon="pi pi-tag"
              onClick={ValidarCupomDescontoVDesconto}
              style={{
                color: CorFonte(),
                backgroundColor: CorFundo(),
                border: "none",
                width: "100%",
              }}
            />
          </div>
        </Dialog>
      )}

      <div
        class="descricao-carrinho"
        style={{ backgroundColor: CorFundo(), color: CorFonte() }}
      >
        {props.tipo == 0 ? (
          <h1>
            <BsFillCartFill
              style={{ marginRight: "10px" }}
              color={CorFonte()}
            />
            {Mesa_Descricao == ""
              ? "Carrinho"
              : Mesa_Descricao + " " + Mesa_Numero}{" "}
          </h1>
        ) : (
          <h1>
            <BsBasket2Fill style={{ marginRight: "10px" }} color={CorFonte()} />
            Finalização
          </h1>
        )}
      </div>

      <div className="lista" style={{ top: "65px", width: "100%" }}>
        {Mesa_Descricao == "" ? (
          <>
            <span
              className="p-float-label"
              style={{ marginTop: "25px", width: "100%" }}
            >
              <Dropdown
                className="z-auto"
                style={{ width: "100%" }}
                value={deliveryOption}
                options={deliveryOptions}
                onChange={(e) => setDeliveryOption(e.value)}
                itemTemplate={itemTemplate}
                valueTemplate={SelectitemTemplate}
                placeholder="Selecione uma opção"
              />
              <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                Como deseja receber seu pedido?
              </label>
            </span>

            <span
              className="p-float-label"
              style={{ marginTop: "25px", width: "100%" }}
            >
              <InputMask
                id="telefone"
                name="telefone"
                label="Telefone"
                mask="(99) 99999-9999"
                floatLabel
                style={{ width: "100%" }}
                onChange={handlePhoneChange}
                value={telefone}
              />
              <label htmlFor="username" style={{ marginLeft: "-10px" }}>
                Celular
              </label>
            </span>

            <span
              className="p-float-label"
              style={{ marginTop: "25px", width: "100%" }}
            >
              <InputText
                id="nome"
                name="nome"
                label="Nome"
                floatLabel
                style={{ width: "100%" }}
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
              <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                Nome
              </label>
            </span>
            {TemFidelidade ? (
              <>
                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputMask
                    id="cpf"
                    name="cpf"
                    label="CPF"
                    mask="999.999.999-99"
                    floatLabel
                    style={{ width: "100%" }}
                    value={CPF}
                    onChange={(e) => setCPF(e.target.value)}
                  />
                  <label htmlFor="cpf" style={{ marginLeft: "-10px" }}>
                    CPF
                  </label>
                </span>
                <span
                  style={{ fontSize: "0.7rem", color: "rgba(223,0,0,0.5)" }}
                >
                  Obrigatório para programa de fidelidade
                </span>
                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputMask
                    id="nasci"
                    name="nasci"
                    label="Data de Nascimento"
                    mask="99/99/9999"
                    floatLabel
                    style={{ width: "100%" }}
                    value={DataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  />
                  <label htmlFor="nasci" style={{ marginLeft: "-10px" }}>
                    Data de Nascimento
                  </label>
                </span>
                <span
                  style={{ fontSize: "0.7rem", color: "rgba(223,0,0,0.5)" }}
                >
                  Obrigatório para programa de fidelidade
                </span>
                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputText
                    id="ponto"
                    name="ponto"
                    label="Meus Pontos"
                    floatLabel
                    style={{ width: "100%" }}
                    value={MeusPontos}
                    disabled={true}
                  />
                  <label htmlFor="ponto" style={{ marginLeft: "-10px" }}>
                    Meus Pontos
                  </label>
                </span>
              </>
            ) : null}

            {deliveryOption == "Delivery" ? (
              <>
                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <Dropdown
                    className="z-auto"
                    style={{ width: "100%" }}
                    value={bairro}
                    options={Bairros}
                    onChange={(e) => SelecionouBairro(e)}
                    itemTemplate={itemTemplate}
                    valueTemplate={SelectitemTemplate}
                    placeholder="Selecione uma opção"
                    filter={true}
                  />
                  <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                    Bairro
                  </label>
                </span>

                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputText
                    id="nome"
                    name="nome"
                    label="Nome"
                    floatLabel
                    style={{ width: "100%" }}
                    value={rua}
                    onChange={(e) => setRua(e.target.value)}
                  />
                  <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                    Rua
                  </label>
                </span>

                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputText
                    id="nome"
                    name="nome"
                    label="Nome"
                    floatLabel
                    style={{ width: "100%" }}
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                  />
                  <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                    Número
                  </label>
                </span>

                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputText
                    id="nome"
                    name="nome"
                    label="Nome"
                    floatLabel
                    style={{ width: "100%" }}
                    value={complemento}
                    onChange={(e) => setComplemento(e.target.value)}
                  />
                  <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                    Complemento
                  </label>
                </span>
              </>
            ) : null}

            <span
              className="p-float-label"
              style={{ marginTop: "25px", width: "100%" }}
            >
              <Dropdown
                className="z-auto"
                style={{ width: "100%" }}
                value={Pagamento}
                options={TipoPagamento}
                onChange={(e) => setPagamento(e.value)}
                itemTemplate={itemTemplatePagamento}
                valueTemplate={SelectitemTemplatePagamento}
                placeholder="Meio de pagamento"
              />
              <label htmlFor="pagamento" style={{ marginLeft: "-10px" }}>
                {" "}
                Meio de pagamento
              </label>

              {TemPìx && TotalTax() > 0 ? (
                <>
                  {PIX ? (
                    <>
                      <h3
                        style={{ marginTop: "10px", color: "rgba(0,0,0,0.7)" }}
                      >
                        *Taxa Serviço Mercado Pago -{" "}
                        {formatoBrasileiro.format(TotalTax())}
                      </h3>
                    </>
                  ) : null}
                </>
              ) : null}
            </span>

            {/* {VaiTroco ? (
              <>
                <span
                  className="p-float-label"
                  style={{ marginTop: "25px", width: "100%" }}
                >
                  <InputNumber
                    inputId="currency-us"
                    value={troco}
                    onValueChange={(e) => settroco(e.value)}
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    style={{ width: "100%", textAlign: "right" }}
                  />

                  <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                    Troco
                  </label>
                </span>
              </>
            ) : null} */}
          </>
        ) : (
          <></>
        )}

        <ul
          className="product-list"
          style={{ marginTop: "25px", width: "100%" }}
        >
          {DadosCarrinho.map((produto, index) => (
            <>
              <ProdutoCarrinho
                fidelidade={TemFidelidade}
                produto={produto}
                Descricao={Descricao}
                SomaTotal={SomaTotal}
                index={index}
                deletar={Deletar}
                DadosCarrinho={DadosCarrinho}
                ForaEstoque={AlgumProdutoForaDeEstoque}
                ProdutoExclusivoVembuscar={ProdutoExclusivoVembuscar}
                ProdutoExclusivoDelivery={ProdutoExclusivoDelivery}
                AddPontoUsado={AddPontoUsado}
                RemovePontoUsado={RemovePontoUsado}
                PontosDisponivel={PontosDisponivel}
                OpcaoSelecionada={deliveryOption}
              />
            </>
          ))}
        </ul>

        <div>
          <span
            className="p-float-label"
            style={{ marginTop: "25px", width: "100%" }}
          >
            <InputNumber
              value={ValorPedido()}
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              style={{ width: "100%", textAlign: "right" }}
              readonly
              className="p-inputtext-sm"
            />
            <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
              Produtos
            </label>
          </span>

          {deliveryOption == "Delivery" ? (
            <>
              <span
                className="p-float-label"
                style={{ marginTop: "25px", width: "100%" }}
              >
                <InputNumber
                  value={ValorDelivery()}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: "100%", textAlign: "right" }}
                  readonly
                />
                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  <span style={{ color: "green" }}>(+)</span> Delivery
                </label>
              </span>
            </>
          ) : null}

          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <label
                className="p-float-label"
                htmlFor="nome"
                style={{ fontSize: "12px" }}
              >
                <span style={{ color: "maroon" }}>(-)</span> Desconto
              </label>
              <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                <InputNumber
                  value={ValorDesconto()}
                  readonly
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ textAlign: "right", width: "100%" }}
                  // className="input-number"
                />
                <Button
                  label="Cupom"
                  icon="pi pi-tag"
                  // className="button-coupon"
                  onClick={ValidaCupomDescontoV}
                  style={{
                    backgroundColor: CorFundo(),
                    border: "1px solid " + CorFundo(),
                    color: CorFonte(),
                    width: "190px",
                  }}
                />
              </div>
            </div>
            {CupomDescontoV != "" && CupomDescontoV != undefined ? (
              <>
                <span style={{ color: "black" }}>
                  Cupom <b>"{CupomDescontoV}"</b> aplicado com sucesso.
                </span>
              </>
            ) : null}
          </>

          <CardDescontoFidelidade />

          {/* //{PIX ? <><h3 style={{ marginTop: '10px', color: 'rgba(0,0,0,0.7)' }}>*Taxa Serviço PIX GooPedir - {formatoBrasileiro.format(TotalTax())}</h3></> : null} */}
          {PIX ? (
            <>
              <span
                className="p-float-label"
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  width: "100%",
                }}
              >
                <InputNumber
                  value={TotalTax()}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: "100%", textAlign: "right" }}
                  readonly
                />
                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  <span style={{ color: "blue", marginLeft: "" }}>(*)</span>{" "}
                  Taxa Mercado Pago
                </label>
              </span>
            </>
          ) : null}

          {DescontoFidelidade > 0 ? (
            <>
              <span
                className="p-float-label"
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  width: "100%",
                }}
              >
                <InputNumber
                  value={DescontoFidelidade}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: "100%", textAlign: "right" }}
                  readonly
                />
                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  <span style={{ color: "rgb(31, 183, 108)", marginLeft: "" }}>
                    (=)
                  </span>{" "}
                  Desconto Programa de Fidelidade
                </label>
              </span>
            </>
          ) : null}

          {/* ExibirTaxa */}

          <span
            className="p-float-label"
            style={{ marginTop: "25px", marginBottom: "25px", width: "100%" }}
          >
            <InputNumber
              value={TotalPedidoComPix()}
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              style={{ width: "100%", textAlign: "right" }}
              readonly
            />
            <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
              <span style={{ color: "blue", marginLeft: "" }}>(=)</span> Total
            </label>
          </span>
          {PedidoMin > 0 ? (
            <>
              <span
                className="p-float-label"
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  width: "100%",
                }}
              >
                <InputNumber
                  value={PedidoMin}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{ width: "100%", textAlign: "right" }}
                  readonly
                />
                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  <span
                    style={{
                      color: ValorPedido() > PedidoMin ? "green" : "red",
                      marginLeft: "",
                    }}
                  >
                    (*) Pedido Mínimo
                  </span>
                </label>
              </span>
            </>
          ) : null}

          {TemFidelidade ? (
            <>
              <span
                className="p-float-label"
                style={{
                  marginTop: "25px",
                  marginBottom: "25px",
                  width: "100%",
                }}
              >
                <InputNumber
                  value={ValorPedidoFidelidade()}
                  style={{ width: "100%", textAlign: "right" }}
                  readonly
                />
                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  <span style={{ color: "green", marginLeft: "" }}>
                    Pontos Fidelidade
                  </span>
                </label>
              </span>
            </>
          ) : null}
        </div>

        <div className="footer-buttons-carrinho">
          {FinalizandoPedido ? (
            <>
              <button
                className="carrinho-button"
                style={{ backgroundColor: CorFundo(), color: CorFonte() }}
                onClick={FinalizarPedido}
              >
                <BsBasket2Fill
                  onClick={FinalizarPedido}
                  style={{
                    marginRight: "10px",
                    backgroundColor: CorFundo(),
                    color: CorFonte(),
                  }}
                />
                Finalizar Pedido
              </button>
            </>
          ) : null}
        </div>
        {ModalTroco ? (
          <>
            <div
              style={{
                position: "fixed",
                bottom: 0,
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.9)", // Cor de fundo com transparência
                padding: "20px",
                borderTop: "1px solid #ccc", // Borda superior para separação
                boxSizing: "border-box", // Incluir padding e border na largura total
                zIndex: 9999, // Z-index alto para ficar sobreposto
                textAlign: "center", // Centraliza conteúdo horizontalmente
              }}
            >
              <span>
                <b>Informe o valor que você precisa de troco</b>
              </span>
              <br></br>
              <span>
                Total Pedido {formatoBrasileiro.format(TotalPedido())}
              </span>
              <span
                className="p-float-label"
                style={{ marginTop: "25px", width: "100%" }}
              >
                <InputNumber
                  inputId="currency-us"
                  value={troco}
                  onValueChange={(e) => settroco(e.value)}
                  mode="currency"
                  currency="BRL"
                  locale="pt-BR"
                  style={{
                    width: "100%",
                    textAlign: "right",
                    marginBottom: "15px",
                  }}
                />

                <label htmlFor="nome" style={{ marginLeft: "-10px" }}>
                  Troco
                </label>
              </span>
              <button
                className="carrinho-button"
                style={{ backgroundColor: CorFundo(), color: CorFonte() }}
                onClick={FinalizaTroco}
              >
                <BsBasket2Fill
                  onClick={FinalizaTroco}
                  style={{
                    marginRight: "10px",
                    backgroundColor: CorFundo(),
                    color: CorFonte(),
                  }}
                />
                Finalizar Pedido
              </button>
            </div>
          </>
        ) : null}

        {ModalTrocoDialog ? (
          <>
            <Dialog
              header="Troco"
              visible={ModalTrocoDialog}
              onHide={() => setModalTrocoDialog(false)}
            >
              <div className="p-fluid">
                <h1>Precisa de troco?</h1>
                <div
                  style={{ display: "flex", gap: "15px", marginTop: "10px" }}
                >
                  <Button
                    style={{
                      backgroundColor: "#A8001C",
                      borderColor: "#A8001C",
                    }}
                    label="Não"
                    className="button-não"
                    onClick={() => NaoPrecisaTroco()}
                  />
                  <Button
                    label="Sim"
                    style={{
                      backgroundColor: "#269931",
                      borderColor: "#269931",
                    }}
                    className="button-sim"
                    onClick={() => setModalTrocoDialog(false)}
                  />
                </div>
              </div>
            </Dialog>
          </>
        ) : null}
      </div>
    </>
  );
}
