import axios from "axios";
import React, { useState, useEffect } from "react";
import { BuscaLocal } from "../../conexao/localstorageBusca";

export function ImagemProduto(props) {
  const [imagemUrl, setImagemUrl] = useState("/sem-foto.jpg");

  //"https://fotos.goopedir.com/fotos/empresa/"+btoa(response.data[0][1])

  useEffect(() => {
    var foto = "";

    if (props.foto != undefined) {
      if (props.foto.length > 0) {
        foto = props.foto;
      }
    }

    async function fetchImagem() {
      try {
        const response = await axios.get("https://fotos.goopedir.com/", {
          headers: {
            nome: props.id,
            "Content-Type": "application/json",
          },
        });

        if (response.data.status) {
          setImagemUrl(response.data.link);
        } else {
          if (props.foto == undefined) {
            setImagemUrl("/sem-foto.jpg");
          } else if (props.foto == "sem-foto") {
            setImagemUrl("/sem-foto.jpg");
          } else if (props.foto == "false") {
            setImagemUrl("/sem-foto.jpg");
          } else {
            setImagemUrl("https://goopedir.com/uploads/" + props.foto);
          }
        }
      } catch (error) {
        //////console.error(error);
      }
    }

    if (props.ifood) {
      setImagemUrl(props.ifood);
    } else {
      fetchImagem();
    }
  }, []);

  const CarregarFoto = () => {
    ////////////console.log('Carregar Foto');
  };

  function imageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  //loja-altera-imagem

  const handlePhotoUpload = (event, id) => {
    // Obtém o arquivo de imagem carregado pelo usuário
    if (BuscaLocal("loja-altera-imagem") === "true") {
      //////////console.log(id);
      const file = event.target.files[0];

      // Converte o arquivo em um objeto URL que pode ser exibido na página
      const photoUrl = URL.createObjectURL(file);

      const reader = new FileReader();

      imageToBase64(file)
        .then((base64String) => {
          // Faça algo com a string base64 aqui
          //////////console.log(base64String);
          const headers = {
            "Content-Type": "application/json",
            nome: id,
          };

          axios
            .post("https://fotos.goopedir.com/", JSON.stringify(base64String), {
              headers,
            })
            .then((response) => {})
            .catch((error) => {
              ////console.error('Erro ao enviar foto:', error);
            });
        })
        .catch((error) => {
          ////console.error(error);
        });

      // reader.onload = (e) => {
      //     // Obtém o valor base64 do arquivo de imagem
      //     const base64Image = e.target.result;

      //     //////////console.log(base64Image);

      //     fetch('https://fotos.goopedir.com', {
      //         method: 'POST',
      //         headers: {
      //             'Content-Type': 'application/json',
      //             'nome': props.id
      //         },
      //         body: JSON.stringify(base64Image)
      //     })
      //         .then(response => response.json())
      //         .then(data => //////////console.log(data))
      //         .catch(error => ////console.error(error));

      // };
      // reader.readAsDataURL(file);
      // Define o objeto URL da imagem no estado
      // setPhoto(photoUrl);
    }
  };

  return (
    <>
      <label htmlFor={props.id}>
        {
          <img
            src={imagemUrl}
            style={{ width: "100%", borderRadius: "5%" }}
            alt={props.id}
          />
        }
      </label>
      {BuscaLocal("loja-altera-imagem") == "true" ? (
        <input
          type="file"
          id={props.id}
          name={props.id}
          onChange={(event) => handlePhotoUpload(event, props.id)}
          accept="image/*"
          style={{ display: "none" }}
        />
      ) : null}
    </>
  );
}
