import { Categoria } from "./categoria/categoria";
import "./home.css";
import { Rodape } from "./rodape/rodape";
import { API, API2 } from "../../conexao/api";
import { useState, useEffect, useRef } from "react";
import { Cabecalho } from "../cabecalho/cabecalho";
import { CorFonte, CorFundo, UserId } from "../util/validaAbertura";
import Footer from "../footer/footer";
import { useParams, Link } from "react-router-dom";
import { GravaLocal } from "../../conexao/localstorage";
import {
  DescricaoProduto,
  PrimeiraLetraMaiuscula,
  truncateString,
} from "../util/primeiraLetraMaiuscula";
import { InputText } from "primereact/inputtext";
import { NaoHaProdutos } from "./produto/SemProduto";
import {
  DadosPrecoProduto,
  formatoBrasileiro,
  ValorProduto,
} from "../util/formatacaoMoeda";
import Modal from "../modal/modal";
import { Produto } from "./produto/produto";
import { BuscaLocal, findMostSimilar } from "../../conexao/localstorageBusca";
import { CategoriaComponent } from "../produtos/indexCategoria";
import { FaGift } from "react-icons/fa";
import { DadosProduto } from "../produtos";

import { Carousel } from "primereact/carousel";
import { Card } from "primereact/card";

// var empresa = 'tioscooby';
var load = false;
var indexSelecionado = 0;

export function Home(props) {
  const [showModal, setShowModal] = useState(false);
  GravaLocal("loja-altera-imagem", props.edit_imagem);

  const [Categorias, setCategorias] = useState([]);
  const [BuscaNovidade, setBuscaNovidade] = useState([]);
  const [Buscar, setBuscar] = useState("");
  const [dadosProduto, setDadosProduto] = useState([]);
  const [DivSelecionada, setDivSelecionada] = useState(0);
  const [Carregar, setCarregar] = useState(false);
  const { codigo } = useParams();
  const { cupom } = useParams();
  const { celular } = useParams();
  const [UsuarioID, setUserID] = useState(0);
  const [Fidelidade, setFidelidade] = useState(false); //

  // Para funcionar a mesa deve ser seguido os seguinte parametro
  // goopedir|mesa|mesa|1
  // goopedir|mesa|Nome da mesa/comanda|codigo
  // deve ser criptografado 3x com base64
  //1º Z29vcGVkaXJ8bWVzYXxtZXNhfDE=
  //2º WjI5dmNHVmthWEo4YldWellYeHRaWE5oZkRFPQ==
  //3º V2pJNWRtTkhWbXRoV0VvNFlsZFdlbGxZZUhSYVdFNW9aa1JGUFE9PQ==

  useEffect(() => {
    if (cupom != undefined) {
      GravaLocal("CUPOM_LINK", cupom);
    } else {
      GravaLocal("CUPOM_LINK", "");
    }

    if (celular) {
      API2.get(`rr/index.php?UserID=${UserId()}&Celular=${celular}`).then(
        function (response) {
          let retorno = JSON.parse(response.data.content);
          let DadosCli = JSON.parse(retorno.cliente);
          console.log(DadosCli);
        }
      );
    }

    if (codigo != undefined) {
      var mesa = atob(codigo);
      mesa = atob(mesa);
      mesa = atob(mesa);

      const parts = mesa.split("|");

      if (parts[0] == "goopedir") {
        GravaLocal("MESA_DESCRICAO", PrimeiraLetraMaiuscula(parts[1]));
        GravaLocal("MESA_NUMERO", parts[2]);
      }
    } else {
      GravaLocal("MESA_DESCRICAO", "");
      GravaLocal("MESA_NUMERO", 0);
    }
    setUserID(UserId());
  }, []);

  useEffect(() => {
    if (!load) {
      if (UsuarioID > 0) {
        load = true;

        var URL = "categoria.php?codigo=" + UsuarioID;
        if (Buscar != "") {
          URL = URL + "&busca=" + Buscar;
        }

        API.get(URL).then(function (dados) {
          try {
            GravaLocal("categoria_selecionada", dados.data[0]["id"]);
          } catch (error) {}
          setCategorias(dados.data);
        });

        URL = `novidade.php?codigo=${UsuarioID}`;

        API.get(URL).then(function (dados) {
          console.log(dados.data);
          setBuscaNovidade(dados.data);
        });
      }
    }
  }, [Buscar, UsuarioID]);

  useEffect(() => {
    if (Carregar) {
      CarregarDados();
    }
  }, [Carregar, UsuarioID]);

  function CarregarDados() {
    var URL = "categoria.php?codigo=" + UsuarioID;
    if (Buscar != "") {
      URL = URL + "&busca=" + Buscar;
    }

    API.get(URL).then(function (dados) {
      try {
        GravaLocal("categoria_selecionada", dados.data[0]["id"]);
      } catch (error) {}
      setCategorias(dados.data);
    });

    URL = `novidade.php?codigo=${UsuarioID}`;

    API.get(URL).then(function (dados) {
      console.log(dados.data);
      setBuscaNovidade(dados.data);
    });
  }

  function LoadPage(codigo, fidelidade) {
    setUserID(codigo);
    setCarregar(true);
    setFidelidade(parseInt(fidelidade) == 1);
    console.log(fidelidade);
  }

  const result = findMostSimilar("Coca cola zero lata");

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  function SelectProduct(codigo) {
    console.log(codigo);
    GravaLocal("soma_sabores", 0);
    GravaLocal("soma_sabores_selecionado", 0);
    GravaLocal("pizza_tipo_valor", 0);
    GravaLocal("produto_selecionado_dados", []);
    GravaLocal("produto_selecionada", codigo);
    GravaLocal("quantidade_sabor_selecionado", 0);
    GravaLocal("pizza_quantidade", 0);
    setShowModal(true);
  }

  function ProdutoNovidade({ product }) {
    console.log(product);

    function Imagem() {
      if (product.img_ifood) {
        return product.img_ifood;
      } else {
        return `https://fotos.goopedir.com/fotos/${btoa(product.id)}`;
      }
    }

    return (
      <>
        <style>
          {`
                .p-carousel-indicator {
                    background-color: ${CorFundo()};
                }
                .p-carousel-indicator.p-highlight .p-link {
                    background-color: ${CorFundo()};
                }

                .p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight{
                    background-color: ${CorFundo()};
                }
                    
                `}
        </style>
        <Card
          title={""}
          style={{ width: "96%", margin: "10px", position: "relative" }}
          onClick={() => SelectProduct(product.id)}
        >
          <img
            src={Imagem()}
            alt={product.nome_item}
            style={{ width: "100%", objectFit: "cover", borderRadius: "10px" }}
          />
          <h2>{product.nome_item}</h2>
          <ValorProduto produto={product} />
        </Card>
      </>
    );
  }

  function Novidade() {
    const responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: "600px",
        numVisible: 1,
        numScroll: 1,
      },
    ];

    return (
      <>
        <h1 style={{ fontSize: "1.1rem", margin: "10px" }}>Novidade</h1>
        <div style={{ marginRight: "10px" }}>
          <Carousel
            value={BuscaNovidade}
            itemTemplate={(product) => <ProdutoNovidade product={product} />}
            numVisible={1}
            numScroll={1}
            // responsiveOptions={responsiveOptions}
            circular
            autoplayInterval={1000}
            showIndicators
            showNavigators={false}
          />
        </div>
      </>
    );
  }

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal ? (
        <>
          <Modal onClose={handleCloseModal} cor="Black">
            <Produto
              key={"prod_" + BuscaLocal("produto_selecionada")}
              codigo={BuscaLocal("produto_selecionada")}
              onClose={handleCloseModal}
              DadosPrecoProduto={DadosPrecoProduto}
            />
          </Modal>
        </>
      ) : null}
      <Cabecalho onLoad={LoadPage} fidelidade={Fidelidade}>
        <div style={{ padding: "10px", marginBottom: "60px" }}>
          {BuscaNovidade ? <>{isMobile() ? <Novidade /> : null}</> : null}
          <CategoriaComponent Categorias={Categorias} />
        </div>
        <Rodape />
      </Cabecalho>

      {/* <Footer /> */}
    </>
  );
}
